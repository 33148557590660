<template>
  <v-main>
    <v-container fill-height>
      <v-row align="center">
        <v-col cols="12" justify-self="center">
          <error-page
            :description="$t('error_codes.forbidden')"
            error="403"
          ></error-page>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ErrorPage from "./../../components/commons/error";
export default {
  data: () => ({}),
  components: {
    ErrorPage,
  },
};
</script>
